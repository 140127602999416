export const TOP_MENU_ITEMS = {
    SIMULATION: "Simulation",
    EDIT: "Edit",
    VIEW: "View",
    SETTINGS: "Settings",
    HELP: "Help",
};

export const HELP_PAGE_URL =
    "https://github.com/tossha/urbain/wiki/%D0%98%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-Urbain";
